import React from 'react';
import DataGridList from "../../components/CommonList/DataGridList.js";

const Index = () => {

    const columns = [
        { name: 'ID', index: 'broadcast_id', type: 'number' },
        { name: 'Name', index: 'name', type: 'text' },
        { name: 'Enabled', index: 'is_active', type: 'input-switch' },
        { name: 'Last Modified', index: 'modified', type: 'date' }
    ];

    const options = {
        crud: true,
        add: {
            route: "/manage/token/form"
        },
        edit: {
            route: "/manage/token/form"
        },
        details: {
            // route: "/admin/token/details"
            route: ""
        },
        delete: {
            route: "token/"
        },
        enabledOpt: {
            url_edit: 'token/',
            field: 'is_active'
        },
        export: {
            route: "/manage/token/export"
        },
    }

    const url = "tokens"

    const filters = [
        { name: "Date From", index: 'modified_after', type: 'date'},
        { name: "Date To", index: 'modified_before', type: 'date'},
        { name: "Search", index: 'search', type: 'text'},
    ];



    return (
        <DataGridList url={url} columns={columns} options={options} filters={filters}/>
    );
};

export default Index;
